/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", system-ui;
  font-weight: 400;
  color: #e1e1e1;
  line-height: 1.3;
}

img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}

input,
select,
textarea {
  background-color: transparent;
  outline: none;
}

button {
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: 0;
}

body {
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  background-color: #252525;
}

.navbar-brand {
  background: white;
  border-radius: 40px 0 0 40px;
  padding: 5px 30px;
}

.navbar-brand img {
  display: block;
  width: 100px;
}

.navbar {
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.navbar-menu {
  width: 100%;
  display: flex;
  justify-content: center;
}

.navbar-menu-navigation {
  position: fixed;
  display: flex;
  align-items: center;
  background-color: #00000030;
  border-radius: 50px;
  margin-top: 30px;
}

.navbar-menu-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 30px;
}

.navbar-menu a {
  display: block;
  text-decoration: none;
  font-size: 16px;
  padding: 0px 20px;
  font-weight: 500;
}

.navbar-item i {
  margin-right: 10px;
}
.navbar-item{
  position: relative;
  padding: 0 10px;
}
.navbar-item::after {
  position: absolute;
  left: 50%;
  bottom: -10px;
  content: "";
  display: block;
  border-radius: 50px;
  width: 0px;
  height: 3px;
  background: #fff;
  transition: all .2s linear;
  transform: translateX(-50%);
}

.navbar-item:hover::after {
  width: 100%;
}

.navbar-user {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar-user-cart {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
}

.navbar-user-cart i {
  color: inherit;
  display: block;
}

.navbar-user-cart:hover {
  background-color: #d2cfcf;
}

.navbar-user-login {
  background-color: #ffffff;
  padding: 8px 20px;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  gap: 10px;
  align-items: center;
}

.navbar-user-login:hover {
  background-color: #d2cfcf;
}

.container {
  width: 100%;
  padding: 15px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #f8f9fa; /* Light background for visibility */
  border: 1px solid #ddd; /* Light border for visibility */
}

/* Small devices (up to 576px) */
.container-sm {
  max-width: 540px;
  margin: 0 auto;
}

/* Medium devices (up to 768px) */
.container-md {
  max-width: 720px;
  margin: 0 auto;
}

/* Large devices (up to 992px) */
.container-lg {
  max-width: 960px;
  margin: 0 auto;
}

/* Extra-large devices (up to 1200px) */
.container-xl {
  max-width: 1140px;
  margin: 0 auto;
}

/* Extra-extra-large devices (1200px and up) */
@media (min-width: 1200px) {
  .container-xl {
    max-width: 1200px;
  }
}

/* App.css */
.slick-prev, .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}